<template>
   <div class="webstyle-logo text-center">
      <img :src="`${base_url}/apps/assets/img-logoLogin.php?webstyle=${webstyle}`" alt=""/>
   </div>
</template>

<script>
// @ is an alias to /src
'use strict';
import {mapActions} from 'vuex';
import {mapGetters} from 'vuex';


export default {
   emits: [],

   components: {
   },// /components{}

   data (){
      return {
      };
   },// /data()

   computed:{
      webstyle () {
         return this.$store.state.server.config.webstyle;
      },
      ...mapGetters([
         'base_url',
      ]),
   }, // /computed{}

   methods: {
      ...mapActions([
      ]),
   },// methods{}

   mounted (){
   },// /mounted()
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Medium and up */
/*@media screen and (min-width: 768px) {}*/

/* Medium only */
/*@media screen and (min-width: 768px) and (max-width: 991px) {}*/

/* Large and up */
/*@media screen and (min-width: 992px) {}*/

/* Large only */
/*@media screen and (min-width: 992px) and (max-width: 1199px) {}*/

/* X-Large and up */
/*@media screen and (min-width: 1200px) {}*/
</style>
