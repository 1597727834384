/* jshint node:true */

const util = {};

util.clone = ( value ) => {
   return JSON.parse( JSON.stringify( value ) );
};// /clone()

util.has_prop = ( o_obj, c_prop ) => {
   return Object.prototype.hasOwnProperty.call( o_obj, c_prop );
};// /has_prop()


util.object_pick = ( o_obj, a_keys ) => {
   var i, l, c_key, o_new = {};

   for( i = 0, l = a_keys.length; i < l; i++ ){
      c_key = a_keys[ i ];
      if( util.has_prop( o_obj, c_key ) ){
         o_new[ c_key ] = o_obj[ c_key ];
      }
   }// /for()

   return o_new;
};// /object_pick()
   
/**
 * 12-16-2014
 * This method tries to convert a string into an integer. If it fails we
 * return a default number.
 * ~~ Scott Johnson
 */
util.to_int = function( cString, nDefault ) {
   var n = parseInt( cString, 10 );
   
   nDefault = nDefault || 0;
   
   if ( isNaN( n ) ) {
      return nDefault;
   }
   
   return n;
};// /to_int()

util.wait = function( n_ms ){
   return new Promise(function( resolve ){
      setTimeout( function(){
         resolve();
      }, n_ms );
   });
};// /wait()

// ###-###-###
util.format_phone_local = function( c_phone ){
   var c_digits = ''.concat( c_phone ).replace(/\D/g, '');
   var a_match = c_digits.match(/^(\d{3})(\d{3})(\d{4})$/);

   if( !a_match ){
      return c_phone;
   }

   return ''.concat( a_match[1],'-', a_match[2],'-',a_match[3] );
};// /format_phone_local()

module.exports = util;
