'use strict'; // jshint ignore:line
// import RequestError from '@/lib/request-error';

class Model {
   _keys = [];
   _attributes = null;

   constructor( o_props = {} ) {
      const o_attrs = this.constructor.attributes;
      this._attributes = o_attrs;
      this._keys = Object.keys( o_attrs );
      this._keys.sort();

      this._keys.forEach( ( k ) => {
         const c_get_attr = `get_${k}_attribute`;
         const c_set_attr = `set_${k}_attribute`;
         
         Object.defineProperty( this, k, {
            get: () => {
               const value = this._attributes[k];
               if( Reflect.has( this, c_get_attr ) ){
                  return this[c_get_attr]( value );
               }
               return value;
            },// /get()

            set: ( value ) => {
               if( Reflect.has( this, c_set_attr ) ){
                  return this[c_set_attr]( value );
               }
               this._attributes[k] = value;
            },// /set()
         });
      });

      this.assign( o_props );
   }// /constructor()

   static get attributes(){
      return {
         id: 0,
         client_id: 0,
         comment: '',
         is_development: 0,
         name: '',
         time_add: null,
         time_update: null,
      };
   }// /static get attributes()

   get keys(){
      return this._keys;
   }// /get keys()

   assign(){
      const a_props = JSON.parse( JSON.stringify( [].slice.call( arguments ) ) );

      a_props.forEach( ( o_props ) => {
         if( !o_props ){
            return;
         }
         
         const o_picked = this.pick( this.keys, o_props );
         Object.assign( this, o_picked );
      } );
   }// /assign()

   pick( keys, props = this ){
      const o_new = {};
      const a_keys = [...keys];

      a_keys.map(function( c_key ){
         if( Reflect.has( props, c_key ) ){
            o_new[ c_key ] = props[ c_key ];
         }
      });

      return o_new;
   }// /pick()
   
   /**
    * This method tries to convert a string into an integer. If it fails returns
    * a default value.
    * ~~ Scott Johnson
    */
   to_int( c_string, n_default ) {
      var n = parseInt( c_string, 10 );
      
      n_default = n_default || 0;
      
      if ( isNaN( n ) ) {
         return n_default;
      }
      
      return n;
   }// /to_int()

   toJSON(){
      const o_props = {};

      this.keys.forEach(( c_attr )=>{
         o_props[ c_attr ] = this._attributes[ c_attr ];
      });

      return o_props;
   }// /toJSON()

}// /class Model()

export default Model;
